import React from 'react'
import Layout from '../layouts/Layout'
import OverviewBanner from '../components/OverviewBanner'
import EarnCredentialOverview from '../components/EarnCredentialOverview'
import NewsOffers from '../components/NewsOffers'
import VerifyCredential from '../components/VerifyCredential'
import Credentials from '../components/Credentials'
import WhatIs from '../components/WhatIs'
import RoleMenu from '../components/RoleMenu'

export const OverviewTemplate = context => {
  const {
    pageContext: {
      data,
      data: {
        slug,
        title,
        hideRolesMenu,
        htmlContent,
        credentialsTitle,
        credentialsList,
        categorizedCredentials
      },
      lang
    }
  } = context

  return (
    <Layout title={title} lang={lang} slug={slug}>
      {/* Overview page Banner */}
      <OverviewBanner currentSlug={slug} lang={lang} />
      
      <div className="cert-site-bg--trailhead-icons slds-p-bottom--x-large">
        {/* ROLE MENU */}
        { !hideRolesMenu && (
          <RoleMenu currentSlug={slug} lang={lang} />
        )}
        
        {/* HTML CONTENT */}
        <div dangerouslySetInnerHTML={{__html: htmlContent}}></div>

        {/* List of credentials */}
        {credentialsList && credentialsList.length > 0 && (
          <>
            <div className="cert-site_title slds-p-top--large"><h1>{credentialsTitle}</h1></div>
            <Credentials names={credentialsList} lang={lang}/>
          </>
        )}

        {/* Categorized Credentials */}
        {categorizedCredentials && categorizedCredentials.length > 0 && (
          <>
            {categorizedCredentials.map((category, index) => (
              <div key={`category-${index}`}>
                <div className="cert-site_title slds-p-top--large"><h1>{category.title}</h1></div>
                <Credentials names={category.credentials} lang={lang}/>
              </div>
            ))}
          </>
        )}

        {/* What is .... */}
        <WhatIs item={data} />
      </div>
     
      <div className="slds-p-top--xx-large slds-p-horizontal_medium">
        {/* Earn your Credential */}
        <EarnCredentialOverview lang={lang} data={[]} />
      </div>
        
     

      {/* News and Offers */}
      <NewsOffers lang={lang} />

      {/* Verify a Credential */}
      <VerifyCredential lang={lang} />
    </Layout>
  )
}

export default OverviewTemplate
