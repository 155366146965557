import React from 'react'
import Hyphenated from 'react-hyphen'
import Truncate from 'react-truncate'
import I18n from '../utils/I18n'
import Translation from '../utils/Translation'
import overviews from "../../data/overviews.json"

const HeroCards = ({slugs, lang}) => {
  const i18n = new I18n({lang})

  let cards = []
  let addSpecialPaddings = false

  slugs.forEach(cardSlug => {
    const itemEN = Translation.getItems(overviews, [cardSlug], 'slug', 'en')[0] || {}
    const itemTranslated = Translation.getItems(overviews, [cardSlug], 'slug', lang)[0] || itemEN

    if(cardSlug === 'mulesoftoverview') {
      console.log('cardSlug')
      addSpecialPaddings = true
    }

    if(Object.keys(itemEN).length > 0) {
      cards.push({
        slug: cardSlug, 
        type: 'overview',
        cta_url: `/${lang}/credentials/${itemEN.slug}`,
        cta_txt: i18n.text('credentialCard_learnMore'),
        cta_txt_now: null,
        itemTitle: itemTranslated.heroCardTitle || itemTranslated.title || itemEN.heroCardTitle || itemEN.title,
        itemUrl: `/${lang}/credentials/${itemEN.slug}`,
        imageUrl: itemEN.heroCardImage,
        descriptionShort: itemTranslated.heroCardDescription || itemTranslated.cardText || itemEN.heroCardDescription || itemEN.cardText
      })
    }
  })

  console.log(addSpecialPaddings)

  return (
    <div className="cert-overview-hero_card">
      <div className="credentialBanner-card">
        <div className="slds-grid slds-wrap slds-grid_pull-padded-medium">
          {cards.map((card, index) => (
            <div 
              key={`overview-card_${index}`} 
              className={`slds-size--1-of-1 slds-large-size--1-of-2 slds-p-horizontal_medium hero_card_${index} ${addSpecialPaddings ? `hero-card_${card.slug}` : '' }`}>
              <div className="slds-grid">
                <div className="slds-col slds-p-right_large">
                  <div className={`hero-img-container_${card.slug}`}>
                    <img className={`credentialBanner-card_image`} alt="Logo for Salesforce Certified Professional" src={card.imageUrl}></img>
                  </div>
                </div>

                <div className="slds-col">
                  <div className="credentialBanner-card_title">
                    <a href={card.itemUrl} dangerouslySetInnerHTML={{ __html: card.itemTitle }}></a>
                  </div>
        
                  <div className="credentialBanner-card_description">
                    <Hyphenated>
                      <Truncate lines={3} ellipsis={<span>...</span>}>
                        {card.descriptionShort}
                      </Truncate>
                    </Hyphenated>
                  </div>

                  <div className="slds-p-top_medium">
                    <a className="slds-button slds-button_brand" href={card.cta_url} > 
                      <Hyphenated>{card.cta_txt}</Hyphenated>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
)
}

export default HeroCards
